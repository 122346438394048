<template>
  <div class="tree-okr">
    <b-card>
      <b-row class="mx-0 align-items-center px-50 top-search">
        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center pl-0 filter-data">
            <feather-icon
              class="cursor-pointer border round p-50 accordion-button collapsed"
              icon="SlidersIcon"
              size="32"
              v-b-toggle.filter-collapse
            />
            <div class="d-flex align-items-center justify-content-end search-okr w-50">
              <b-input-group class="input-group-merge round w-100">
                <b-form-input
                  class="d-inline-block"
                  placeholder="Search by Name or OKR Title"
                />
              </b-input-group>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- Vertical okr View Tree Design -->
      <div class="okrgrid-body teamgoal-scroll px-0">
        <div class="okr-tree mt-1" id="okr-tree">
          <ul class="m-0 p-0 main-tree">
            <li class="list-unstyled">
              <b-card class="relative mb-0 mainokr-dept">
                <div class="emp-pic">
                  <b-img
                    :src="require('@/assets/images/logo/dckap-logo.svg')"
                    @click="collapse()"
                    class="w-100 pro-img"
                    fluid
                    alt="profile photo"
                  ></b-img>
                </div>
                <div class="expand-div" v-b-toggle.detail-okr1>
                  <div
                    class="d-flex align-items-center justify-content-center expand-old"
                  >
                    <feather-icon icon="PlusIcon" class="before-expand" />
                    <feather-icon icon="MinusIcon" class="after-expand" />
                  </div>
                </div>
              </b-card>
              <ul class="sub-tree mt-3 p-0 mx-auto">
                <!-- <li class="list-unstyled" v-for="node in nodes.children">
                      <b-card class="relative mb-0 company-dept">
                        <div class="mb-0 w-100">
                          <div class="goal-header text-center d-flex align-items-center justify-content-between">
                            <h5 class="mb-0 obje-name font-weight-bolder">Revenue for Q1, 2024</h5>
                            <div class="circle_percent" data-percent="50">
                              <div class="circle_inner">
                                <div class="round_per"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="obj-period d-flex align-ites-center">
                          <div class="quarter-data">
                            <h6 class="mb-0 text-left">Period</h6>
                            <p class="value text-left mb-0">Q6</p>
                          </div>
                        </div>
                      </b-card>
                    </li> -->
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="scroll tree-okr">
        <div id="svg-tree"></div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import QuillInput from "@/components/QuillInput.vue";

import $ from "jquery";
import Vue from "vue";
import { bus } from "@/main.js";
import axios from "@axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
//import ApexTree from "apextree";
export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    QuillInput,
    // Navbar Components
    BCarousel,
    BCarouselSlide,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      nodes: [],
      data: {
        id: "ms",
        data: {
          imageURL: "https://i.pravatar.cc/300?img=68",
          name: "Margret Swanson",
        },
        children: [
          {
            id: "mh",
            data: {
              imageURL: "https://i.pravatar.cc/300?img=69",
              name: "Mark Hudson",
            },
            children: [
              {
                id: "kb",
                data: {
                  imageURL: "https://i.pravatar.cc/300?img=65",
                  name: "Karyn Borbas",
                },
              },
              {
                id: "cr",
                data: {
                  imageURL: "https://i.pravatar.cc/300?img=60",
                  name: "Chris Rup",
                },
              },
            ],
          },
          {
            id: "cs",
            data: {
              imageURL: "https://i.pravatar.cc/300?img=59",
              name: "Chris Lysek",
            },
            children: [
              {
                id: "Noah_Chandler",
                data: {
                  imageURL: "https://i.pravatar.cc/300?img=57",
                  name: "Noah Chandler",
                },
              },
              {
                id: "Felix_Wagner",
                data: {
                  imageURL: "https://i.pravatar.cc/300?img=52",
                  name: "Felix Wagner",
                },
              },
            ],
          },
        ],
      },
      zoom: {
        enabled: true,
        type: "x",
        resetIcon: {
          offsetX: -10,
          offsetY: 0,
          fillColor: "#fff",
          strokeColor: "#37474F",
        },
        selection: {
          background: "#90CAF9",
          border: "#0D47A1",
        },
      },
      options: {
        contentKey: "data",
        width: "100%",
        height: 600,
        nodeWidth: 320,
        nodeHeight: 150,
        fontColor: "#636363",
        borderColor: "#BFBFBF",
        childrenSpacing: 50,
        siblingSpacing: 20,
        direction: "top",
        enableExpandCollapse: true,
        nodeTemplate: (content) => this.getContent(content),
        enableToolbar: true,
      },
    };
  },
  mounted() {
    // $(".circle_percent").each(function() {
    //     var $this = $(this),
    //     $dataV = $this.data("percent"),
    //     $dataDeg = $dataV * 3.6,
    //     $round = $this.find(".round_per");
    //     $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
    //     $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
    //     $this.prop('Counter', 0).animate({Counter: $dataV},
    //     {
    //       duration: 2000,
    //       easing: 'swing',
    //       step: function (now) {
    //               $this.find(".percent_text").text(Math.ceil(now)+"%");
    //           }
    //       });
    //     if($dataV >= 51){
    //       $round.css("transform", "rotate(" + 360 + "deg)");
    //       setTimeout(function(){
    //         $this.addClass("percent_more");
    //       },1000);
    //       setTimeout(function(){
    //         $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
    //       },1000);
    //     }
    //   });

    this.getObjectiveTree();
    this.updateProgress();
    $(function () {
      $(".cookr-tree ul").hide();
      $(".okr-tree>ul").show();
      $(".okr-tree ul.active").hide();
      $(".okr-tree li").on("click", function (e) {
        $(this).addClass("open-tree");
        $(this).siblings("li").removeClass("open-tree");
        $(this).siblings("li").find("ul").hide();
        var children = $(this).find("> ul");
        if (children.is(":visible")) children.hide("fast").removeClass("active");
        else children.show("fast").addClass("active");
        e.stopPropagation();
      });
    });
  },
  beforeDestroy() {
    // Remove the click event listener when the component is destroyed
    this.$el.removeEventListener("click", this.handleDocumentClick);
  },
  methods: {
    handleDocumentClick(event) {
      let targetElement = event.target;
      // Traverse up the DOM tree to find the element with the class 'clickable'
      while (targetElement && !targetElement.classList.contains("nodes")) {
        targetElement = targetElement.parentElement;
      }
      if (targetElement) {
        const dataValue = targetElement.getAttribute("data-item");
        console.log("Data value:", dataValue);
      }
    },
    ParentClicked() {
      alert("sdf");
    },
    getContent(content) {
      if (content.hashid == "parent") {
        return (
          `<div class="p-1 mainokr-dept nodes" data-item="` +
          content.hashid +
          `">
                  <div class="emp-pic">
                    <img src="` +
          require("@/assets/images/logo/dckap-logo.svg") +
          `" class="w-100 pro-img" fluid alt="profile photo"></img>
                  </div>
                </div>`
        );
      } else if (content.type == "Organisation") {
        return (
          `<div class="p-1 company-dept nodes"  data-item="` +
          content.hashid +
          `">
                  <div class="mb-0 w-100" @click="ParentClicked()">
                    <div class="goal-header text-center d-flex align-items-center justify-content-between">
                      <h5 class="mb-0 obje-name font-weight-bolder">` +
          content.title +
          `</h5>
                      <div class="circle_percent" data-percent="` +
          content.progress +
          `">
                        <div class="circle_inner">
                          <div class="round_per"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="obj-period d-flex align-ites-center">
                    <div class="quarter-data">
                      <h6 class="mb-0 text-left">Period</h6>
                      <p class="value text-left mb-0">` +
          content.period +
          `</p>
                    </div>
                  </div>
                </div>`
        );
      } else if (content.type == "Team") {
        return (
          `<div class="p-1 company-dept nodes"  data-item="` +
          content.hashid +
          `" >
                  <div class="mb-0 w-100">
                    <badge variant="info" class="badge badge-light-warning">` +
          content.status +
          `</badge>
                    <div class="goal-header text-center d-flex align-items-center justify-content-between">
                      <h5 class="mb-0 obje-name font-weight-bolder"> ` +
          content.title +
          ` </h5>
                      <div class="circle_percent" data-percent="` +
          content.progress +
          `">
                        <div class="circle_inner">
                          <div class="round_per"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center okr-objtype">
                    <div class="obj-name d-flex align-ites-center">
                      <div class="quarter-data">
                        <h6 class="mb-0 text-left">Team</h6>
                        <p class="value text-left mb-0">` +
          content.team_name +
          `</p>
                      </div>
                    </div>
                    <div class="obj-period d-flex align-ites-center">
                      <div class="quarter-data">
                        <h6 class="mb-0 text-left">Period</h6>
                        <p class="value text-left mb-0">` +
          content.period +
          `</p>
                      </div>
                    </div>
                  </div>
                </div>`
        );
      } else if (content.type == "Individual") {
        return (
          `<div class="p-1 company-dept nodes" data-item="` +
          content.hashid +
          `" >
                  <div class="mb-0 w-100">
                    <badge variant="info" class="badge badge-light-warning">` +
          content.status +
          `</badge>
                    <div class="goal-header text-center d-flex align-items-center justify-content-between">
                      <h5 class="mb-0 obje-name font-weight-bolder"> ` +
          content.title +
          ` </h5>
                      <div class="circle_percent" data-percent="` +
          content.progress +
          `">
                        <div class="circle_inner">
                          <div class="round_per"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center okr-objtype">
                    <div class="obj-name d-flex align-ites-center">
                      <div class="quarter-data">
                        <h6 class="mb-0 text-left">Name</h6>
                        <p class="value text-left mb-0">` +
          content.user_name +
          `</p>
                      </div>
                    </div>
                    <div class="obj-period d-flex align-ites-center">
                      <div class="quarter-data">
                        <h6 class="mb-0 text-left">Period</h6>
                        <p class="value text-left mb-0">` +
          content.period +
          `</p>
                      </div>
                    </div>
                  </div>
                </div>`
        );
      }
    },
    getCurrentYear() {
      const currentDate = new Date();
      return currentDate.getFullYear();
    },
    getCurrentQuarter() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed, so add 1

      // Calculate the quarter based on the current month
      if (currentMonth >= 1 && currentMonth <= 3) {
        return "Q1";
      } else if (currentMonth >= 4 && currentMonth <= 6) {
        return "Q2";
      } else if (currentMonth >= 7 && currentMonth <= 9) {
        return "Q3";
      } else {
        return "Q4";
      }
    },
    getObjectiveTree() {
      axios
        .post("Objectives/get-tree", {
          year: this.getCurrentYear(),
          quarter: this.getCurrentQuarter(),
        })
        .then((response) => {
          if (response.data.success) {
            this.nodes = response.data.data;

            var tree = new ApexTree(document.getElementById("svg-tree"), this.options);
            var graph = tree.render(this.nodes);

            this.$nextTick(() => {
              this.updateProgress();
              const elements = this.$el.querySelectorAll(".nodes");
              elements.forEach((element) => {
                element.addEventListener("click", this.handleDocumentClick);
              });
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Unable to fetch Objective Tree",
                icon: "EditIcon",
                variant: "danger",
                text: response.data.message,
              },
            });
          }
        });
    },
    updateProgress() {
      // Circle Progress bar Design
      $(".circle_percent").each(function () {
        var $this = $(this),
          $dataV = $this.data("percent"),
          $dataDeg = $dataV * 3.6,
          $round = $this.find(".round_per");
        $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
        $this.append(
          '<div class="circle_inbox"><span class="percent_text"></span></div>'
        );
        $this.prop("Counter", 0).animate(
          { Counter: $dataV },
          {
            duration: 2000,
            easing: "swing",
            step: function (now) {
              $this.find(".percent_text").text(Math.ceil(now) + "%");
            },
          }
        );
        if ($dataV >= 51) {
          $round.css("transform", "rotate(" + 360 + "deg)");
          setTimeout(function () {
            $this.addClass("percent_more");
          }, 1000);
          setTimeout(function () {
            $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
          }, 1000);
        }
      });
    },
  },
};
</script>
